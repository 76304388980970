.capabilities {
    background-color: var(--main-color-2);
    padding: 60px 0;
    color: #fff;
}

.capabilities h2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    margin-bottom: 80px;
}

.capabilities__list {
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.capabilities__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 22px;
}

.capabilities__item > svg {
    min-height: 100px;
}

.capabilities__item p svg {
    margin: 0 auto 14px;
    display: block;
}

.capabilities__item p {
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    max-width: 180px;
    text-align: center;
}

@media (max-width: 850px) {
    .capabilities__list {
        flex-wrap: wrap;
        justify-content: space-around;
    }
}

@media (max-width: 480px) {
    .capabilities h2 {
        font-size: 25px;
        line-height: 29px;
        margin-bottom: 66px;
    }

    .capabilities__list {
        gap: 60px;
    }
}