.intro-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
}

.intro-slider::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, 0.25);
}

.intro-slider .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.intro-slider .swiper-pagination {
    left: 0;
    bottom: 90px;
    opacity: 0.75;
}

.intro-slider .swiper-pagination-bullet {
    border: 2px solid #FFFFFF;
    background: transparent;
    opacity: 1;
    width: 15px;
    height: 15px;
    margin: 0 12px;
    transition: var(--transition);
}

.intro-slider .swiper-pagination-bullet-active {
    background-color: #fff;
}