.table-container h4 {
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    text-transform: uppercase;
    margin-bottom: 40px;
    color: var(--main-color-2);
}

.table-container > .container {
    position: relative;
}

.table-scroll {
    margin-left: -20px;
    padding-left: 20px;
    padding-right: 20px;
    width: calc(100% + 40px);
    overflow-x: auto;
}

.table-scroll::-webkit-scrollbar{
    background-color:transparent;
}

.table {
    border-collapse: collapse;
    text-align: left;
    width: 100%;
    white-space: nowrap;
    position: relative;
}

.table thead {
    font-weight: 600;
    font-size: 25px;
    line-height: 29px;
    color: var(--main-color);
}

.table thead th:first-child {
    padding-left: 30px;
}

.table thead th:last-child {
    padding-right: 30px;
}

.table tbody td:first-child {
    padding-left: 30px;
}

.table tbody td:last-child {
    padding-right: 30px;
}

.table th {
    padding-bottom: 15px;
}

.table th:not(:first-child) {
    padding: 0 20px 15px;
}

.table td {
    padding: 20px 0;
}

.table td:not(:first-child) {
    padding: 20px;
}

.table tbody {
    font-weight: 400;
    font-size: 25px;
    line-height: 29px;
    color: var(--main-color);
}

.table tbody tr {
    background: #F3F9FF;
    border-bottom: 5px solid #fff;
}

.table.table--small thead, .table.table--small tbody {
    font-size: 15px;
    line-height: 18px;
}

.table.table--small td {
    padding: 15px 15px;
}

.table.table--small th:not(:first-child) {
    padding: 0 15px 25px;
}

.table.table--small th {
    padding-bottom: 25px;
}

.table--search {
    margin-bottom: 130px;
}

.table--rent {
    width: 725px;
    margin-bottom: 50px;
}

.swipe-icon {
    position: absolute;
    top: 0;
    height: 40px;
    right: 0;
    display: flex;
    align-items: center;
}

.swipe-icon svg {
    width: 110px;
    height: auto;
}

.table-main {
    display: flex;
    flex-direction: column;
    gap: 100px;
}

@media (max-width: 650px) {
    .swipe-icon svg {
        width: 52px;
    }

    .swipe-icon {
        height: 19px;
    }

    .table-container h4 {
        font-size: 20px;
        text-align: center;
    }

    .table thead, .table tbody{
        font-size: 15px;
        line-height: 18px;
    }

    .table th {
        padding-bottom: 30px;
    }

    .table th:not(:first-child) {
        padding: 0 20px 30px;
    }

    .table td {
        padding: 13px 0;
    }

    .table td:not(:first-child) {
        padding: 13px 20px;
    }
}