.btn {
    appearance: none;
    width: 100%;
    min-width: 115px;
    height: 60px;
    text-decoration: none;
    cursor: pointer;
    border: 2px solid var(--main-color-2);
    border-radius: var(--border-radius);
    background-color: var(--main-color-2);
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #fff;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: var(--transition);
}

.btn:disabled {
    pointer-events: none;
    opacity: 0.7;
}

.btn svg path {
    transition: var(--transition);
}

.btn svg {
    min-width: fit-content;
}

.btn.btn--dark {
    border: 2px solid var(--main-color);
    background-color: var(--main-color);
}

.btn:hover {
    background: #fff;
    color: var(--main-color);
}

.btn.btn--transparent {
    background-color: transparent;
    border: 2px solid transparent;
    color: var(--main-color);
    width: fit-content;
    white-space: nowrap;
}

.btn.btn--transparent:hover {
    color: var(--main-color-2);
}

.btn:hover svg .fill {
    stroke: var(--main-color);
}

.btn:hover svg .stroke {
    stroke: var(--main-color);
}

.btn.btn--transparent:hover svg path {
    fill: var(--main-color-2);
}

.btn-show-more {
    border: none;
    background: transparent;
    cursor: pointer;
    margin: 25px auto 0;
    display: block;
}

@media (max-width: 650px) {
    .btn-show-more {
        margin: 10px auto 0;
    }
}