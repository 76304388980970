.form-search {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(2px);
    border-radius: 50px;
    padding: 14px;
}

.form-search__container {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 50px;
    padding-left: 30px;
}

.form-search__btn {
    width: 70px;
    min-width: 70px;
    height: 70px;
    cursor: pointer;
    background-color: var(--main-color-2);
    border-radius: 100%;
    border: 3px solid var(--main-color-2);
    transition: var(--transition);
}

.form-search__btn:hover {
    background-color: transparent;
}

.form-search__btn svg path {
    transition: var(--transition);
}

.form-search__btn:hover svg path {
    stroke: var(--main-color-2);
}

.form-search .select-search__wrapper {
    height: 50px;
    display: flex;
    align-items: center;
}

.form-search__container > div:nth-child(2) {
    padding: 0 25px;
    margin: 0 25px 0 25px;
    border-left: 1px solid #E6E6E6;
    border-right: 1px solid #E6E6E6;
}

.form-search__btn.form-search__btn--mobile {
    display: none;
}

/* Adaptive */

@media (max-width: 850px) {
    .form-search__container {
        flex-direction: column;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        gap: 42px;
        padding: 30px 28px;
    }

    .form-search {
        -webkit-border-radius: 28px;
        -moz-border-radius: 28px;
        border-radius: 28px;
        width: 400px;
    }

    .form-search__container > div:nth-child(2) {
        border: none;
        padding: 0;
        margin: 0;
    }

    .form-search__btn {
        display: none;
    }
    
    .form-search__btn.form-search__btn--mobile {
        display: block;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        width: 100%;
        margin-top: 15px;
        height: 63px;
    }

    .form-search .select-search__wrapper {
        height: 19px;
        width: 100%;
    }

    .form-search__container .react-datepicker-container {
        height: 19px;
        width: 100%;
    }

    .form-search__container .select-search__menu {
        top: 33px;
        border-width: 4px 1px 1px 1px;
    }

    .form-search .datepicker-wrapper {
        margin-right: 0;
    }

    .form-search__container .select-search-container {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .form-search {
        width: 252px;
    }

    .form-search__container .select-search__placeholder {
        font-size: 16px;
    }

    .form-search__container .react-datepicker__input-container input {
        font-size: 16px;
        padding-left: 24px;
        height: 19px;
        width: 100%;
    }

    .form-search__container .datepicker-wrapper > svg {
        width: 16px;
        height: 18px;
    }
}