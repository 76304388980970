/*.octobus__columns:not(:first-child),*/
/*.octobus__dropdown-trigger,*/
/*#people,*/
.search .vdp-datepicker__calendar-button,
.search .search-from__icon.svg-inline--fa {
    display: none !important;
}

/*#app {*/
/*    overflow: auto;*/
/*}*/
#app .octobus__select:not(.is-multiple):after, #auth .octobus__select:not(.is-multiple):after {
    z-index: 0 !important;
}

.search .vdp-datepicker__calendar {
    right: 0 !important;
}

.search .octobus__columns {
    display: flex !important;
    flex-wrap: wrap !important;
}

.search .octobus__column {
    padding: 0 !important;
    display: block;
    flex-basis: unset !important;
    flex-grow: unset !important;
    flex-shrink: unset !important;
    width: initial !important;
}

#app .search .switch[type=checkbox]:checked + label:before {
    background: #4D72DE !important;
}

.octobus__section.search {
    background: rgba(255, 255, 255, 0.5) !important;
    backdrop-filter: blur(2px) !important;
    border-radius: 50px !important;
    padding: 14px !important;
    display: flex !important;
    max-width: 665px !important;
    margin: 0 auto !important;
}

.search .light-blue .octobus__button.is-primary, .search .light-blue .octobus__button.is-primary[disabled] {
    background: #4D72DE !important;
    opacity: 1 !important;
}

.octobus__section.search > .octobus__container {
    background: #FFFFFF !important;
    border-radius: 40px !important;
    padding: 35px 51px 95px 51px !important;
    position: relative !important;
}

.octobus__section.search > .octobus__container .octobus__column:nth-child(4) {
    display: none !important;
}

#app .search .octobus__button, .search .octobus__button:disabled {
    position: absolute !important;
    width: 100% !important;
    height: 65px !important;
    left: 12px !important;
    bottom: 12px !important;
    background: #4D72DE !important;
    border-radius: 0 0 40px 40px !important;
    color: transparent !important;
    cursor: pointer !important;
    opacity: 1 !important;
}

#app .search .octobus__select:not(.is-multiple):after {
    right: 5px !important;
    border-color: #000 !important;
}

.octobus__section.search .light-blue .octobus__button.is-primary, .octobus__section.search .light-blue .octobus__button.is-primary[disabled] {
    background: #4D72DE !important;
}

.search .octobus__column button::after {
    content: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M30 30L23.3891 23.3773M27.0526 14.5263C27.0526 17.8485 25.7329 21.0346 23.3838 23.3838C21.0346 25.7329 17.8485 27.0526 14.5263 27.0526C11.2041 27.0526 8.01801 25.7329 5.66887 23.3838C3.31973 21.0346 2 17.8485 2 14.5263C2 11.2041 3.31973 8.01801 5.66887 5.66887C8.01801 3.31973 11.2041 2 14.5263 2C17.8485 2 21.0346 3.31973 23.3838 5.66887C25.7329 8.01801 27.0526 11.2041 27.0526 14.5263Z' stroke='white' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E") !important;
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.search .octobus__datepicker-when {
    padding: 0 !important;
}

.search .octobus__columns input[type="text"],
.search .octobus__columns input[type="search"],
.search .octobus__columns input[type="tel"],
.search .octobus__columns input[type="email"] {
    appearance: none !important;
    height: 30px !important;
    display: flex !important;
    outline: none !important;
    border-width: 0 0 2px 0 !important;
    border-style: solid !important;
    border-color: #D6D6D6 !important;
    background: transparent !important;
    width: 100% !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 23px !important;
    color: #4D4D4D !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

#app .search .octobus__columns input:focus, #app .search .octobus__columns select:focus {
    box-shadow: none !important;
}

.search .octobus__columns input[type=search]::-ms-clear,
.search .octobus__columns input[type=search]::-ms-reveal {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
}

.search .octobus__columns input[type="search"]::-webkit-search-decoration,
.search .octobus__columns input[type="search"]::-webkit-search-cancel-button,
.search .octobus__columns input[type="search"]::-webkit-search-results-button,
.search .octobus__columns input[type="search"]::-webkit-search-results-decoration {
    display: none !important;
}

.search .v-autocomplete-input-group {
    position: relative !important;
    display: flex !important;
    align-items: center !important;
    gap: 15px !important;
}

.search .v-autocomplete-input-group::before {
    content: url("data:image/svg+xml,%3Csvg width='17' height='20' viewBox='0 0 17 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.18182 0C6.01265 0.00245748 3.93306 0.831051 2.39923 2.30402C0.865399 3.77699 0.00256773 5.77405 8.70462e-06 7.85714C-0.00258931 9.55945 0.57644 11.2156 1.64827 12.5714C1.64827 12.5714 1.87141 12.8536 1.90786 12.8943L8.18182 20L14.4588 12.8907C14.4915 12.8529 14.7154 12.5714 14.7154 12.5714L14.7161 12.5693C15.7874 11.214 16.3662 9.55866 16.3636 7.85714C16.3611 5.77405 15.4982 3.77699 13.9644 2.30402C12.4306 0.831051 10.351 0.00245748 8.18182 0ZM8.18182 10.7143C7.59338 10.7143 7.01815 10.5467 6.52888 10.2328C6.03961 9.91882 5.65827 9.4726 5.43309 8.95052C5.2079 8.42845 5.14898 7.85397 5.26378 7.29974C5.37858 6.74551 5.66194 6.23642 6.07803 5.83684C6.49412 5.43726 7.02425 5.16514 7.60138 5.0549C8.17852 4.94466 8.77673 5.00124 9.32038 5.21749C9.86403 5.43374 10.3287 5.79994 10.6556 6.2698C10.9825 6.73965 11.157 7.29205 11.157 7.85714C11.156 8.61461 10.8423 9.34079 10.2845 9.8764C9.72677 10.412 8.97059 10.7133 8.18182 10.7143Z' fill='%234D72DE'/%3E%3C/svg%3E") !important;
}

.search .vdp-datepicker {
    position: relative !important;
    display: flex !important;
    align-items: center !important;
    gap: 15px !important;
}

.search .vdp-datepicker > div:nth-child(1) {
    width: 100% !important;
}

.search .vdp-datepicker::before {
    content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.375 0C4.54076 0 4.69973 0.065848 4.81694 0.183058C4.93415 0.300269 5 0.45924 5 0.625V1.25H15V0.625C15 0.45924 15.0658 0.300269 15.1831 0.183058C15.3003 0.065848 15.4592 0 15.625 0C15.7908 0 15.9497 0.065848 16.0669 0.183058C16.1842 0.300269 16.25 0.45924 16.25 0.625V1.25H17.5C18.163 1.25 18.7989 1.51339 19.2678 1.98223C19.7366 2.45107 20 3.08696 20 3.75V17.5C20 18.163 19.7366 18.7989 19.2678 19.2678C18.7989 19.7366 18.163 20 17.5 20H2.5C1.83696 20 1.20107 19.7366 0.732233 19.2678C0.263392 18.7989 0 18.163 0 17.5V3.75C0 3.08696 0.263392 2.45107 0.732233 1.98223C1.20107 1.51339 1.83696 1.25 2.5 1.25H3.75V0.625C3.75 0.45924 3.81585 0.300269 3.93306 0.183058C4.05027 0.065848 4.20924 0 4.375 0ZM1.25 5V17.5C1.25 17.8315 1.3817 18.1495 1.61612 18.3839C1.85054 18.6183 2.16848 18.75 2.5 18.75H17.5C17.8315 18.75 18.1495 18.6183 18.3839 18.3839C18.6183 18.1495 18.75 17.8315 18.75 17.5V5H1.25Z' fill='%234D72DE'/%3E%3C/svg%3E") !important;
}

.search .v-autocomplete-list {
    position: absolute !important;
    background: #fff !important;
    padding: 10px 20px !important;
    font-size: 16px !important;
    border-radius: 10px !important;
    box-shadow: 0 0 10px !important;
    z-index: 1 !important;
}

.search .v-autocomplete-list-item {
    cursor: pointer !important;
    transition: var(--transition) !important;
}

.v-autocomplete-list-item:hover {
    color: var(--main-color-2) !important;
}

/*.result {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    width: 100%;*/
/*    margin: 0 auto;*/
/*    padding: 0;*/
/*}*/


/*.result section:nth-child(1) {*/
/*    display: none;*/
/*    order: 4;*/
/*}*/

/*.result section:nth-child(2) {*/
/*    order: 2;*/
/*    background: #fff;*/
/*    border-radius: 10px 10px 0 0;*/
/*    margin-top: 15px;*/
/*    padding: 10px;*/
/*    width: 100%;*/
/*}*/

/*.result section:nth-child(3) {*/
/*    order: 1;*/
/*    background: #FFFFFF;*/
/*    padding: 10px;*/
/*}*/

/*.result section:nth-child(4) {*/
/*    order: 3;*/
/*    background: #fff;*/
/*    padding: 0 85px 15px;*/
/*    overflow: auto;*/
/*    max-height: 320px;*/
/*}*/

/*h1.octobus__subtitle {*/
/*    color: #3B414A;*/
/*    font-size: 25px;*/
/*    padding: 0;*/
/*}*/

/*.octobus__title {*/
/*    font-size: 23px;*/
/*}*/

/*.trips-menu {*/
/*    font-weight: 500;*/
/*    font-size: 20px;*/
/*    line-height: 29px;*/
/*    color: #131313;*/
/*    gap: 20px;*/
/*    width: 100%;*/
/*    flex-wrap: nowrap;*/
/*}*/

/*.trips-menu > div {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    gap: 10px;*/
/*    white-space: nowrap;*/
/*}*/

/*.ticket {*/
/*    margin-top: 20px;*/
/*    padding-top: 20px;*/
/*    border-top: 1px solid #3B414A;*/
/*    font-size: 17px;*/
/*    line-height: 20px;*/
/*    color: #131313;*/
/*    width: fit-content;*/
/*}*/

/*.ticket > .octobus__columns {*/
/*    gap: 20px;*/
/*    width: 100%;*/
/*    flex-wrap: nowrap;*/
/*}*/

/*.ticket > .octobus__columns > .octobus__column:nth-child(1) {*/
/*    min-width: 200px;*/
/*    max-width: 200px;*/
/*}*/

/*.ticket > .octobus__columns > .octobus__column:nth-child(2) {*/
/*    min-width: 100px;*/
/*    max-width: 100px;*/
/*    display: flex;*/
/*    flex-wrap: wrap;*/
/*    gap: 10px;*/
/*}*/

/*.ticket > .octobus__columns > .octobus__column:nth-child(3) {*/
/*    min-width: 230px;*/
/*    max-width: 230px;*/
/*}*/

/*.ticket > .octobus__columns > .octobus__column:nth-child(4) {*/
/*    min-width: 150px;*/
/*    max-width: 150px;*/
/*}*/

/*.ticket > .octobus__columns > .octobus__column:nth-child(5) .octobus__columns {*/
/*    gap: 20px;*/
/*}*/

/*.ticket > .octobus__columns > .octobus__column:nth-child(6) {*/
/*    width: 100%;*/
/*}*/

/*.ticket__price {*/
/*    min-width: 110px;*/
/*    max-width: 110px;*/
/*}*/

/*.trips-menu .octobus__column:nth-child(1) {*/
/*    min-width: 200px;*/
/*    max-width: 200px;*/
/*}*/

/*.trips-menu .octobus__column:nth-child(2) {*/
/*    min-width: 100px;*/
/*    max-width: 100px;*/
/*}*/

/*.trips-menu .octobus__column:nth-child(3) {*/
/*    min-width: 230px;*/
/*    max-width: 230px;*/
/*}*/

/*.trips-menu .octobus__column:nth-child(4) {*/
/*    min-width: 150px;*/
/*    max-width: 150px;*/
/*}*/

/*.trips-menu .octobus__column:nth-child(5) {*/
/*    min-width: 110px;*/
/*    max-width: 110px;*/
/*}*/

/*.trips-menu .octobus__column:nth-child(6) {*/
/*    width: 100%;*/
/*}*/

/*.octobus__button {*/
/*    background: var(--main-color-2);*/
/*    padding: 5px 27px;*/
/*    color: #fff;*/
/*    cursor: pointer;*/
/*}*/

.search .octobus__dropdown-item {
    font-size: 18px !important;
    display: flex !important;
    align-items: center !important;
    gap: 15px !important;
    padding: 0 !important;
}

.search .counter {
    display: flex !important;
    align-items: center !important;
    gap: 5px !important;
}

.search .counter > * {
    min-width: 20px !important;
    text-align: center !important;
}

.search .octobus__dropdown-menu {
    display: flex !important;
    min-width: unset !important;
    padding-top: unset !important;
    position: unset !important;
    top: unset !important;
    z-index: 0 !important;
}

.search .octobus__dropdown-content {
    background-color: unset !important;
    border-radius: unset !important;
    box-shadow: unset !important;
    padding-bottom: unset !important;
    padding-top: unset !important;
}

.light-blue .counter .fa-minus svg, .light-blue .counter .fa-plus svg {
    font-size: 15px !important;
    color: var(--main-color-2) !important;
    cursor: pointer !important;
}

.count, .select-title, .octobus__column select {
    user-select: none !important;
}

.search .octobus__select {
    display: flex !important;
    align-items: center !important;
    gap: 15px !important;
}

.octobus__select::before {
    content: url("data:image/svg+xml,%3Csvg width='12' height='20' viewBox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.08889 8.77778C3.56667 8.12222 2.75556 7.44444 2.75556 6.38889C2.75556 5.17778 3.87778 4.33333 5.75556 4.33333C7.73333 4.33333 8.46667 5.27778 8.53333 6.66667H10.9889C10.9111 4.75556 9.74444 3 7.42222 2.43333V0H4.08889V2.4C1.93333 2.86667 0.2 4.26667 0.2 6.41111C0.2 8.97778 2.32222 10.2556 5.42222 11C8.2 11.6667 8.75556 12.6444 8.75556 13.6778C8.75556 14.4444 8.21111 15.6667 5.75556 15.6667C3.46667 15.6667 2.56667 14.6444 2.44444 13.3333H0C0.133333 15.7667 1.95556 17.1333 4.08889 17.5889V20H7.42222V17.6111C9.58889 17.2 11.3111 15.9444 11.3111 13.6667C11.3111 10.5111 8.61111 9.43333 6.08889 8.77778Z' fill='%234D72DE'/%3E%3C/svg%3E");
}

#app .search .octobus__column select {
    border-width: 0 0 2px 0 !important;
    border-style: solid !important;
    border-color: #D6D6D6 !important;
    font-size: 20px !important;
    line-height: 23px !important;
    outline: none !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    padding: 0 30px 0 10px !important;
    height: auto !important;
}

#app .search .light-blue .octobus__select.is-primary select {
    border-color: #D6D6D6 !important;
}

.search .octobus__dropdown-trigger {
    position: absolute;
    pointer-events: none;
    opacity: 0;
}

.octobus__dropdown-trigger .octobus__button {
    color: #323741;
    background: transparent;
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 16px;
    height: 30px;
}

.octobus__dropdown-trigger .octobus__button .people-mobile-icon {
    display: flex;
    align-items: center;
    gap: 5px;
}

.octobus__dropdown-trigger .octobus__button .people-mobile-icon .svg-inline--fa {
    font-size: 15px;
}

.octobus__section.search > .octobus__container .octobus__columns:nth-child(3) {
    display: contents !important;
    align-items: center !important;
    font-size: 16px !important;
    gap: 20px !important;
    flex-wrap: wrap !important;
    margin-left: auto !important;
}

.octobus__section.search > .octobus__container .octobus__columns:nth-child(3) .octobus__column:nth-child(3) {
    width: calc(50% - 37px);
}

.octobus__section.search > .octobus__container .octobus__columns:nth-child(3) .octobus__column:nth-child(3) .vdp-datepicker {
    margin-top: 30px;
}

.octobus__section.search > .octobus__container .octobus__columns:nth-child(4) {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
}


.octobus__section.search > .octobus__container .octobus__columns:nth-child(1) {
    gap: 40px 70px !important;
    margin: 0 0 20px 0 !important;
    display: flex !important;
    flex-wrap: wrap !important;
}

.octobus__section.search > .octobus__container .octobus__columns:nth-child(2) {
    margin: 0 auto 0 0 !important;
}

.octobus__section.search > .octobus__container .octobus__columns:nth-child(1) .octobus__column:nth-child(1),
.octobus__section.search > .octobus__container .octobus__columns:nth-child(1) .octobus__column:nth-child(2) {
    width: calc(100% / 2 - 37px) !important;
    padding: 0 !important;
    display: flex !important;
    flex-basis: unset !important;
    flex-grow: unset !important;
    flex-shrink: unset !important;
}

.octobus__section.search > .octobus__container .octobus__columns:nth-child(1) .octobus__column:nth-child(3) {
    width: 100%;
    padding: 0 !important;
}

.search .octobus__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.checkout, .octobus__section.success {
    height: 100%;
    overflow: auto;
}

.search .octobus__control {
    position: relative !important;
    text-align: left !important;
}

.test {
    height: 100%;
    overflow: hidden;
}

.test .main-intro__content {
    padding: 0;
}

.contact-info-wrapper .contact-info > .octobus__columns > .octobus__column:nth-child(2) .octobus__field {
    display: flex;
}

.custom-switch {
    position: absolute;
    top: 80px;
    right: 50px;
    margin: 20px auto;
    height: 45px;
    width: 350px;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(2px);
    border-radius: 40px;
    z-index: 350;
    box-shadow: 0 0 5px #ccc;
}

.custom-switch input {
    display: none;
}

.custom-switch .custom-switch-text {
    position: relative;
    float: left;
    width: 50%;
    height: 45px;
    line-height: 45px;
    text-align: center;
    z-index: 2;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    white-space: nowrap;
    transition: var(--transition);
    user-select: none;
}

.selection {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 50%;
    background: #FFF;
    height: 45px;
    border-radius: 20px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    z-index: 1;
    transition: 0.15s ease-out;
}

.custom-switch--change .selection {
    left: 50%;
}

.custom-switch .custom-switch__right {
    color: #292121;
}

.custom-switch--change .custom-switch__right {
    color: var(--main-color-2);
}

.custom-switch .custom-switch__left {
    color: var(--main-color-2);
}

.custom-switch.custom-switch--change .custom-switch__left {
    color: #292121;
}

.main-intro__content.hidden {
    opacity: 0;
    pointer-events: none;
    position: absolute;
}

.main-intro__content.active {
    opacity: 1;
    pointer-events: initial;
    position: relative;
}

.search .octobus__field, .search .field {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #4C4C4C;
}

.search .field {
    margin-right: 30px;
    margin-left: 10px;
}


.duration-block [data-icon="long-arrow-alt-down"] {
    display: none;
}

.ticket > .octobus__columns:nth-child(2) {
    margin-top: 40px;
}

.change {
    display: none;
}

.result__title > div {
    flex-direction: column;
    margin-bottom: 40px;
}

/*#app > div > section.octobus__section.tickets.result__tickets > div > div:nth-child(3) > div > div > div > div.octobus__column.is-one-third {*/
/*    margin-left: 70px;*/
/*}*/

#app > div > section.octobus__section.tickets.result__tickets br {
    display: none;
}

#app > div > section.octobus__section.tickets.result__tickets > div > div:nth-child(3) > div > div > div {
    margin-top: 20px;
}

.bus {
    display: flex;
    margin: 40px 0;
}

#app > div > section.octobus__section.checkout-trip > div > div > div.octobus__columns.is-vcentered > div.octobus__column.is-hidden-mobile.is-paddingless.is-narrow-tablet.is-2-tablet.is-1-desktop.has-text-right-tablet {
    display: none;
}

#app > div > section.octobus__section.contact-info-wrapper > div > div:nth-child(2) {
    margin: 25px 0;
}

.checkout .octobus__select::before, #app > div > section.octobus__section.checkout-trip > div > div > div.octobus__columns.is-vcentered > div.octobus__column.is-paddingless.is-half-desktop.is-half-tablet > div > div.octobus__column.is-hidden-tablet.is-narrow-touch {
    display: none;
}

#app > div > section.octobus__section.route-info > div > div > div {
    gap: 20px;
    font-size: 18px;
    margin-bottom: 20px;
}

#app > div > section.octobus__section.route-info > div > div > div > div:nth-child(3) {
    width: 100%;
}

#app > div > section.octobus__section.route-info > div > div > h2 > div {
    flex-wrap: nowrap;
    gap: 10px;
}

#app > div > section.octobus__section.contact-info-wrapper > div > div.octobus__columns.is-vcentered {
    gap: 20px;
}

@media (min-width: 320px) {
    .search .octobus__column {
        display: block !important;
    }
}

@media (max-width: 1050px) {
    .result section:nth-child(4) {
        padding: 0 30px 15px;
    }
}

@media (max-width: 850px) {
    .test .main-intro__content {
        height: 100vh;
    }

    .test {
        overflow: initial;
    }

    .custom-switch {
        right: 10px;
        top: 65px;
    }

    .test .main-intro__content h1 {
        padding: 0 15px;
    }

    #app {
        width: 100%;
    }

    #app > div > section.octobus__section.octobus__header > div > h1 {
        padding: 0;
    }
}

@media (max-width: 600px) {
    .octobus__section.search > .octobus__container {
        padding: 20px 20px 95px 20px !important;
    }

    .octobus__section.search > .octobus__container .octobus__columns:nth-child(1) {
        gap: 20px 30px !important;
        margin-bottom: 0 !important;
    }

    .octobus__section.search > .octobus__container .octobus__columns:nth-child(2) {
        margin: 15px auto 15px 0 !important;
    }

    .search .vdp-datepicker__calendar {
        top: -77px;
        padding: 0 5px !important;
        right: initial !important;
    }
}

@media (max-width: 480px) {
    .custom-switch {
        right: 0;
        top: 45px;
        width: 300px;
        left: 50%;
        transform: translateX(-50%);
        height: 35px;
    }

    .custom-switch .custom-switch-text {
        font-size: 14px;
        height: 35px;
        line-height: 35px;
    }

    .selection {
        height: 35px;
    }

    .octobus__section.search > .octobus__container .octobus__columns:nth-child(3) .octobus__column:nth-child(3) {
        width: 100% !important;
    }

    .octobus__section.search > .octobus__container .octobus__columns:nth-child(1) .octobus__column:nth-child(1),
    .octobus__section.search > .octobus__container .octobus__columns:nth-child(1) .octobus__column:nth-child(2),
    .octobus__section.search > .octobus__container .octobus__columns:nth-child(1) .octobus__column:nth-child(1) .v-autocomplete,
         .octobus__section.search > .octobus__container .octobus__columns:nth-child(1) .octobus__column:nth-child(2) .v-autocomplete{
        width: 100% !important;
    }

    .search .vdp-datepicker {
        width: 100%;
    }

    .octobus__columns input[type="text"], .octobus__columns input[type="search"], .octobus__columns input[type="tel"], .octobus__columns input[type="email"] {
        font-size: 16px !important;
    }

    .octobus__column select {
        font-size: 16px !important;
    }

}