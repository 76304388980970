.modal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.modal__overlay {
    z-index: 1;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    animation: opacityOverlay .3s linear;
}

.modal__wrapper {
    position: absolute;
    overflow: hidden;
    top: 50%;
    width: 100%;
    max-width: 576px;
    max-height: calc(100% - 40px);
    background: #fff;
    transform: translateY(-50%);
    padding: 65px 40px 40px;
    border-radius: var(--border-radius);
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    box-shadow: 1px 1px 7px 0 #ccc;
    animation: fadeWrapper .3s linear;
}

@keyframes opacityOverlay {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes fadeWrapper {
    0% {opacity: 0; transform: translateY(-60%)}
    100% {opacity: 1; transform: translateY(-50%)}
}

.modal__close {
    position: absolute;
    right: 20px;
    top: 20px;
    border: none;
    background-color: transparent;
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: none;
}

.modal__close::before {
    content: '';
    width: 21px;
    height: 2px;
    background-color: var(--main-color-2);
    display: block;
    transform: rotate(45deg) translateY(-50%);
    top: 50%;
    position: absolute;
}

.modal__close::after {
    content: '';
    width: 21px;
    height: 2px;
    background-color: var(--main-color-2);
    display: block;
    transform: rotate(-45deg) translateY(-50%);
    position: absolute;
    top: 50%;
}

@media (max-width: 620px) {
    .modal__close {
        display: block;
    }

    .modal__wrapper {
        height: 100%;
        max-height: initial;
        max-width: initial;
        padding: 35px 20px 40px;
    }
}
