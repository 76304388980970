.menu {
    position: absolute;
    right: 0;
    top: calc(100% + 10px);
    max-width: 308px;
    width: 100%;
    animation: opacity .3s linear;
}

@keyframes opacity {
    0% {opacity: 0}
    100% {opacity: 1}
}

.menu__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.menu__item a, .menu__item span {
    width: 100%;
    display: block;
    text-decoration: none;
    color: #fff;
    padding: 25px 25px 25px 53px;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    background-color: var(--main-color-2);
    border-top: 1px solid #FFFFFF;
    transition: var(--transition);
    cursor: pointer;
}

.menu__item a:hover, .menu__item span:hover {
    background-color: #2654d7;
}

.menu__item:first-child a, .menu__item:first-child span {
    border-top: 0;
}

.menu__item.menu__item--dropdown span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    user-select: none;
}

.menu__item.menu__item--dropdown span svg {
    transition: var(--transition);
}

.menu__item.menu__item--dropdown span.opened svg {
    transform: rotate(180deg);
}

.menu__item.menu__item--mobile {
    display: none;
}

.menu__item.menu__item--mobile  .mobile-contact {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
}

.menu__item.menu__item--mobile.padding-low .mobile-contact {
    padding: 17px 45px;
}

.mobile-contact:not(.inherit-styled) svg path {
    fill: #fff;
}

.menu__sub a {
    padding: 12px 20px 12px 88px;
    font-size: 18px;
    line-height: 21px;
}

.menu__sub .menu__item:first-child a, .menu__item:first-child span {
    border-top: 1px solid #fff;
}

/* Adaptive */

@media (max-width: 850px) {
    .menu__item.menu__item--mobile {
        display: flex;
    }
}

@media (max-width: 480px) {
    .menu {
        top: 51px;
        max-width: initial;
        height: 100vh;
        background-color: var(--main-color-2);
    }

    .menu__item:last-child a {
        border-bottom: 1px solid #FFFFFF;
    }
}