.time-picker {
    width: 100%;
}

.time-picker span {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: var(--main-color);
    display: block;
    margin-bottom: 15px;
}

.time-picker input {
    appearance: none;
    outline: none;
    border: 2px solid transparent;
    width: 100%;
    height: 60px;
    border-radius: var(--border-radius);
    background-color: #F3F9FF;
    padding: 19px 19px 19px 13px;
    font-weight: 400;
    font-size: 19px;
    line-height: 22px;
    color: #868686;
    transition: var(--transition);
}

.time-picker input:hover, .time-picker input:focus {
    border: 2px solid var(--main-color-2);
}