* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --main-color: #575757;
    --main-color-2: #4D72DE;
    --color-error: #f32020;
    --border-radius: 10px;
    --transition: all 0.3s ease-in;
    --animation-duration: 2s;
    --animation-delay-multiplier: 200ms;
}

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--main-color);
    line-height: 30px;
    font-size: 25px;
}

html, body, #root {
    height: 100%;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.d-none {
    display: none !important;
}

.wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

main {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 70px;
}

footer {
    flex: 0;
}

.container {
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 20px;
}

.title {
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    margin: 70px 0;
    text-align: center;
}

.sub-title {
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    color: var(--main-color-2);
    text-transform: uppercase;
    margin-bottom: 50px;
}

.title-accent {
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    text-transform: uppercase;
    color: var(--main-color-2);
}

.form {
    padding-bottom: 130px;
}

.form__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 50px;
}

.input-wrap, .form__wrap .select-search__wrapper {
    width: calc(100% / 2 - 64px);
    max-width: 536px;
}

.form__btns {
    display: flex;
    margin-top: 50px;
    gap: 60px;
}

.form__btns .btn {
    max-width: 256px;
}

.form__wrap .react-datepicker-container, .form__wrap .time-picker {
    width: calc(100% / 2 - 64px);
    max-width: 536px;
}

.form .add-transplantation {
    max-width: 400px;
    margin: 50px 0;
}

.form--search {
    padding-bottom: 30px;
}

.form--search .btn {
    max-width: 256px;
}

.form__btn {
    width: calc(100% / 2 - 64px);
    max-width: 536px;
    display: flex;
    align-items: flex-end;
}

.btn-rent {
    max-width: 312px;
    margin-top: 50px;
}

.profile-user {
    padding-bottom: 100px;
}

.profile-user__list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 800px;
}

.profile-user__item {
    padding: 20px 19px;
    background: #F3F9FF;
}

.profile-user__item p {
    display: flex;
    gap: 150px;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}

.profile-user__item span {
    min-width: 100px;
    font-weight: 700;
    display: inline-block;
}

.profile-user .btn {
    max-width: 312px;
    margin-top: 50px;
}

.footer {
    background: #575757;
    color: #fff;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    padding: 25px 0;
}

.footer > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}

.footer__logo {
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.footer__list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.footer__item {
    text-align: left;
    width: calc(100% / 3 - 15px);
}

.footer__item a {
    color: #fff;
    transition: var(--transition);
    text-decoration: none;
}

.footer__item a:hover {
    color: var(--main-color-2);
}


/* Footer Payments */
.payments__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.payments__item {}

.payments__link {}

.payments__link > svg {
    max-width: 40px;
    height: initial;
}
/* \Footer Payments */


.information-page {
    padding: 50px;
}

.information-page.small-mb {
    padding-bottom: 0;
}

.information-page p {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 22px;
}

.information-page p img {
    display: block;
    margin: 0 auto;
}

.information-page h2 {
    text-transform: uppercase;
    font-size: 28px;
    margin: 15px 0 25px;
}

.information-page ul ul {
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.information-page > ul > li {
    font-weight: 700;
}

.information-page ul {
    list-style-type: decimal;
}

.information-page .container > ul {
    padding-left: 22px;
}

.information-page ul li {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
}

.information-page ul li li {
    font-size: 18px;
    font-weight: 500;
}

.information-page .nwf li {
    font-weight: 400;
}

.information-page ul ul ul {
    list-style-type: "-";
    margin-top: 10px;
}

.footer__copyright-mobile {
    display: none;
    margin-top: 20px;
    padding: 10px 0 0;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
}


/* Preloader */
.cssload-preloader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}

.cssload-preloader.hidden {
    display: none;
}

.cssload-preloader > .cssload-preloader-box {
    position: absolute;
    height: 29px;
    top: 50%;
    left: 50%;
    transform: translate(-20%, -50%);
    margin: -15px 0 0 -146px;
    /*perspective: 195px;*/
    /*-o-perspective: 195px;*/
    /*-ms-perspective: 195px;*/
    /*-webkit-perspective: 195px;*/
    /*-moz-perspective: 195px;*/
    display: flex;
}

.cssload-preloader .cssload-preloader-box > div {
    height: 40px;
    width: 40px;
    background: var(--main-color-2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    text-shadow: 2px 2px 5px var(--main-color-2);
    margin-left: 5px;
    box-shadow: 0 0 10px var(--main-color-2);
    color: #fff;
}
.cssload-preloader .cssload-preloader-box > div:nth-child(1) {
    animation: wave var(--animation-duration) infinite;
}
.cssload-preloader .cssload-preloader-box > div:nth-child(2) {
    animation: wave var(--animation-duration) infinite calc(var(--animation-delay-multiplier) * 1);
}
.cssload-preloader .cssload-preloader-box > div:nth-child(3) {
    animation: wave var(--animation-duration) infinite calc(var(--animation-delay-multiplier) * 2);
}
.cssload-preloader .cssload-preloader-box > div:nth-child(4) {
    animation: wave var(--animation-duration) infinite calc(var(--animation-delay-multiplier) * 3);
}
.cssload-preloader .cssload-preloader-box > div:nth-child(5) {
    animation: wave var(--animation-duration) infinite calc(var(--animation-delay-multiplier) * 4);
}
.cssload-preloader .cssload-preloader-box > div:nth-child(6) {
    animation: wave var(--animation-duration) infinite calc(var(--animation-delay-multiplier) * 5);
}
.cssload-preloader .cssload-preloader-box > div:nth-child(7) {
    animation: wave var(--animation-duration) infinite calc(var(--animation-delay-multiplier) * 6);
}
.cssload-preloader .cssload-preloader-box > div:nth-child(8) {
    animation: wave var(--animation-duration) infinite calc(var(--animation-delay-multiplier) * 7);
}
.cssload-preloader .cssload-preloader-box > div:nth-child(9) {
    animation: wave var(--animation-duration) infinite calc(var(--animation-delay-multiplier) * 8);
}
.cssload-preloader .cssload-preloader-box > div:nth-child(10) {
    animation: wave var(--animation-duration) infinite calc(var(--animation-delay-multiplier) * 9);
}
.cssload-preloader .cssload-preloader-box > div:nth-child(11) {
    animation: wave var(--animation-duration) infinite calc(var(--animation-delay-multiplier) * 10);
}
.cssload-preloader .cssload-preloader-box > div:nth-child(12) {
    animation: wave var(--animation-duration) infinite calc(var(--animation-delay-multiplier) * 11);
}

@keyframes wave {
    0% { transform: translateY(0) scale(1)}
    50% { transform: translateY(-30px) scale(.75)}
    51% { transform: translateY(-30px) scale(.75)}
    100% { transform: translateY(0) scale(1)}
}


/* \Preloader */


.field-error {
    display: block;
    font-size: 14px;
    margin: 0 5px;
    color: #d72d2d;
}

.form-auth {
    width: 100%;
    height: 100%;
    max-height: 680px;
    overflow-x: auto;
}

.form-auth h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    margin-bottom: 50px;
}

.form-auth__input-wrap {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.form-auth__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}

.forget-psw {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: var(--main-color);
    transition: var(--transition);
}

.forget-psw:hover {
    color: var(--main-color-2);
}

.form-auth__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    margin-top: 50px;
}

.form-auth__input-wrap .input-wrap {
    width: 100%;
    max-width: initial;
}

@media (max-width: 480px) {
    .form-auth h2 {
        font-size: 30px;
        margin-bottom: 30px;
    }

    .forget-psw {
        font-size: 15px;
    }

    .form-auth__buttons {
        gap: 20px;
    }

    .form-auth__buttons .btn:nth-child(1) {
        flex: 1;
    }

    .form-auth .select-search__single-value {
        font-size: 18px;
    }
}

@media (max-width: 350px) {
    .form-auth__buttons {
        flex-wrap: wrap;
    }
}

/* Adaptive */

@media (max-width: 1100px) {
    .footer > .container {
        flex-wrap: wrap;
        gap: 0;
    }

    .footer__logo {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 35px;
    }

    .footer__copyright {
        display: none;
    }

    .footer__copyright-mobile{
        display: block;
    }
    .footer__list {
        margin-bottom: 10px;
    }

    .payments__list {
        flex-direction: row;
    }

    .payments__item {
        margin-right: 10px;
    }
}

@media (max-width: 992px) {
    .form__wrap {
        gap: 35px;
    }

    .input-wrap,
    .form__wrap .select-search__wrapper,
    .form__wrap .react-datepicker-container,
    .form__wrap .time-picker,
    .form__btn {
        width: calc(100% / 2 - 25px);
    }

    .form__wrap--other .input-wrap:nth-child(2) {
        width: 265px !important;
    }

    .form__wrap--other .input-wrap:nth-child(1) {
        width: calc(100% - 320px) !important;
    }
}

@media (max-width: 850px) {
    .profile-user__list {
        width: 100%;
    }

    .profile-user__item p {
        font-size: 15px;
        gap: 10px;
    }

    .profile-user__item {
        padding: 16px 20px;
    }

    .profile-user__item span {
        min-width: 82px;
    }
}

@media (max-width: 760px) {
    .footer__item {
        width: calc(100% / 2 - 15px);
    }
}

@media (max-width: 650px) {
    .input-wrap,
    .form__wrap .select-search__wrapper,
    .form__wrap .react-datepicker-container,
    .form__wrap .time-picker,
    .form__btn {
        width: 100%;
        max-width: initial;
    }

    .form--search .btn {
        margin: 0 auto;
    }

    .title {
        font-size: 30px;
        margin: 55px 0 30px;
        line-height: 35px;
    }

    .sub-title {
        font-size: 20px;
        text-align: center;
    }

    .form__btns--profile {
        gap: 30px;
    }

    .form__btns--profile .btn:nth-child(1) {
        flex: 1;
    }

    .form__wrap--other .input-wrap:nth-child(1),
    .form__wrap--other .input-wrap:nth-child(2){
        width: 100% !important;
    }

}

@media (max-width: 570px) {
    .cssload-preloader .cssload-preloader-box > div {
        height: 20px;
        width: 20px;
        font-size: 16px;
    }
    .cssload-preloader > .cssload-preloader-box {
        transform: translate(-1%, -270%);
    }
}

@media (max-width: 550px) {
    .footer__logo {
        justify-content: center;
    }
    
    .footer__item {
        width: 100%;
        text-align: center;
    }
    .payments__list {
        width: 100%;
        justify-content: center;
    }

    .payments__item:nth-last-of-type(1) {
        margin-right: 0;
    }
}

@media (max-width: 480px) {
    main {
        padding-top: 50px;
    }

    .form--search .btn {
        max-width: 100%;
    }

    .form__btns {
        flex-direction: column;
    }

    .form__btns--profile .btn:nth-child(1) {
        flex: initial;
        max-width: 100%;
    }

    .form__btns--profile .btn:nth-child(2) {
        height: fit-content;
        margin: 0 auto;
    }

    .form .add-transplantation {
        max-width: initial;
        margin: 40px 0 60px;
    }

    .create-flight {
        max-width: initial !important;
    }
}