.select-search-icon {
    position: relative;
}

.select-search__wrapper label {
    width: 100%;
}

.select-search__menu-notice {
    font-size: 16px;
    padding:  0 13px;
    text-align: center;
}

.select-search-icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.select-search-container {
    width: 195px;
}

.select-search__input-container {
    margin: 0 !important;
    padding: 0 !important;
}

.select-search__value-container {
    padding: 0 !important;
}

.select-search__wrapper.select-search__wrapper--default .select-search-container {
    width: 100%;
    z-index: 1;
}

.select-search__wrapper.select-search__wrapper--default .select-search__value-container {
    height: 60px;
}

.select-search__wrapper.select-search__wrapper--default .select-search__input-container {
    font-size: 19px;
}

.select-search__wrapper.select-search__wrapper--default .select-search__control {
    background-color: #F3F9FF;
    border: 2px solid transparent;
    border-radius: var(--border-radius);
    transition: var(--transition);
    padding: 0 60px 0 13px;
}

.select-search__wrapper.select-search__wrapper--default .select-search__control:hover,
.select-search__wrapper.select-search__wrapper--default .select-search__control--is-focused {
    border: 2px solid var(--main-color-2);
}

.select-search-arrow svg {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    pointer-events: none;
}

.select-search__wrapper.select-search__wrapper--default .select-search__menu {
    border-width: 2px;
    top: 50px;
    z-index: -1;
    padding-top: 15px;
}

.select-search__label {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: var(--main-color);
    display: block;
    margin-bottom: 15px;
}

@media (max-width: 480px) {
    .select-search__label {
        font-size: 18px;
    }
}
