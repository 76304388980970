.popular-flights {
    padding-top: 100px;
}

.popular-flights h2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    color: var(--main-color);
    text-align: center;
    margin-bottom: 50px;
}

.popular-flights__list {
    display: flex;
    gap: 34px;
}

.popular-flights__item {
    list-style-type: none;
    height: 400px;
    overflow: hidden;
    position: relative;
    border-radius: var(--border-radius);
    color: #fff;
    width: 100%;
    min-width: 230px;
    display: flex;
    align-items: flex-end;
}

.popular-flights__item img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
    transition: var(--transition);
}

.popular-flights__item:hover img {
    transform: scale(1.05);
}

.popular-flights__content {
    text-align: center;
    padding: 17px 28px;
    background: linear-gradient(180deg, rgba(120, 120, 120, 0) 0%, #151515 100%);
}

.popular-flights__content h3 {
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    text-transform: uppercase;
    margin-bottom: 4px;
}

.popular-flights__content p {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    margin: 0 auto;
}

@media (max-width: 1050px) {
    .popular-flights > .container {
        padding: 0;
    }

    .popular-flights__list {
        gap: 20px;
        padding-bottom: 15px;
        overflow-x: auto;
    }

    .popular-flights__item:first-child {
        margin-left: 20px;
    }

    .popular-flights__item:last-child {
        margin-right: 20px;
    }

    .popular-flights__item {
        min-width: 280px;
    }
}

@media (max-width: 480px) {
    .popular-flights {
        padding-top: 80px;
    }
}