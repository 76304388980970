.datepicker-wrapper {
    position: relative;
}

.form-search .datepicker-wrapper {
    margin-right: 18px;
}

.datepicker-wrapper > svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.react-datepicker__input-container input {
    border: none;
    background-color: transparent;
    padding-left: 35px;
    height: 23px;
    outline: none;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    width: 212px;
}

.react-datepicker {
    border: 0;
    overflow: hidden;
    border-radius: 8px;
    border-top: 4px solid var(--main-color-2);
    color: #fff;
}

.react-datepicker__header {
    background: #323741;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom: 0;
    padding: 12px 13px 0;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 0;
}

.react-datepicker__month {
    background: #323741;
    padding: 10px 13px 13px;
    margin: 0;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    font-family: 'Roboto', sans-serif;
}

.react-datepicker__day-name {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.react-datepicker__day {
    background: #3B414A;
    border-radius: 8px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    transition: var(--transition);
    margin: 1px;
}

.react-datepicker__day:hover {
    background: var(--main-color-2);
}

.react-datepicker__day--outside-month {
    background: transparent;
}

.react-datepicker__day--selected {
    background: var(--main-color-2);
}

.react-datepicker__day--today {
    background-color: rgba(77, 114, 222, 0.7);
}

.react-datepicker__current-month {
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding-bottom: 11px;
}

.react-datepicker__navigation {
    width: 12px;
    height: 12px;
    background: #B0B8CC;
    border-radius: 3px;
}

.react-datepicker__navigation-icon--previous::before {
    width: 0;
    height: 0;
    border-width: 4px 5px 4px 0;
    border-color: transparent #323741 transparent transparent;
    border-style: solid;
    right: 0;
    top: 8px;
    transform: rotate(0deg);
}

.react-datepicker__navigation-icon--next::before {
    width: 0;
    height: 0;
    border-width: 4px 5px 4px 0;
    border-color: transparent #323741 transparent transparent;
    border-style: solid;
    left: 0;
    top: 8px;
    transform: rotate(180deg);
}

.react-datepicker__navigation--previous{
    left: 13px;
}

.react-datepicker__navigation--next {
    right: 13px;
}

.react-datepicker__navigation {
    top: 13px;
    transition: var(--transition);
}

.react-datepicker__navigation:hover *::before {
    border-color: transparent #323741 transparent transparent;
}

.react-datepicker__navigation:hover {
    background: var(--main-color-2);
}

.react-datepicker-popper[data-placement^=bottom] {
    padding-top: 20px;
}

.date-picker-default {
    width: 100%;
    height: 60px;
    background-color: #F3F9FF;
    border: 2px solid transparent;
    border-radius: var(--border-radius);
    transition: var(--transition);
}

.date-picker-default .react-datepicker__input-container {
    height: 100%;
}

.date-picker-default .react-datepicker__input-container input {
    padding: 0 60px 0 13px;
    height: 100%;
    width: 100%;
}

.react-datepicker__label {
    display: block;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: var(--main-color);
    margin-bottom: 15px;
}

.date-picker-default:hover  {
    border: 2px solid var(--main-color-2);
}

@media (max-width: 480px) {
    .react-datepicker__label {
        font-size: 18px;
    }
}

