.input-wrap label span {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: var(--main-color);
    display: block;
    margin-bottom: 15px;
}

.input-wrap input {
    appearance: none;
    outline: none;
    border: 2px solid transparent;
    width: 100%;
    height: 60px;
    border-radius: var(--border-radius);
    background-color: #F3F9FF;
    padding: 19px 13px;
    font-weight: 400;
    font-size: 19px;
    line-height: 22px;
    color: #151515;
    transition: var(--transition);
}

.input-wrap input:focus, .input-wrap input:hover {
    border: 2px solid var(--main-color-2);
}

.input-wrap input._error {
    border: 2px solid var(--color-error);
}

.input-wrap input::placeholder {
    font-weight: 400;
    font-size: 19px;
    line-height: 22px;
    color: #868686;
}

.create-flight {
    max-width: 312px;
}

.form__wrap--other .input-wrap:nth-child(1) {
    max-width: 645px;
}

.form__wrap--other .input-wrap:nth-child(2) {
    width: 421px;
}

@media (max-width: 480px) {
    .input-wrap label span {
        font-size: 18px;
    }

    .input-wrap input, .input-wrap input::placeholder {
        font-size: 18px;
    }
}