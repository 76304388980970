.input-check-box {
    position: relative;
}

.input-checkbox-wrapper {
    user-select: none;
}

.input-checkbox-wrapper label {
    cursor: pointer;
}

.input-checkbox-title {
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;
    font-weight: 400;
    font-size: 20px;
    line-height: 16px;
}

.input-checkbox-icon {
    width: 20px;
    height: 20px;
    border: 2px solid #575757;
    display: block;
    position: relative;
}

.input-checkbox {
    display: none;
}

.input-checkbox-icon::before {
    content: url("data:image/svg+xml,%3Csvg width='23' height='18' viewBox='0 0 23 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 9L8 15L21 2' stroke='%234D72DE' stroke-width='3'/%3E%3C/svg%3E");
    position: absolute;
    left: 0;
    top: -3px;
    transform: scale(0);
    transition: var(--transition);
}

.input-checkbox:checked + .input-checkbox-title .input-checkbox-icon::before {
    transform: scale(1);
}

@media (max-width: 480px) {
    .input-checkbox-title {
        font-size: 15px;
    }
}