.header {
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    display: flex;
    z-index: 370;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

.logo {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: auto;
}

.logo img {
    width: 230px;
}

.header > .container {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}

.header__contacts {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 75px;
}

.header__contacts a {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: var(--main-color);
    transition: var(--transition);
    text-decoration: none;
    margin-right: 50px;
}

.header__contacts a:nth-last-of-type(1) {
    margin-right: 0;
}

.header__contacts a svg {
    transition: var(--transition);
}

.header__contacts a:hover {
    color: var(--main-color-2);
}

.header__contacts a:hover svg {
    transform: scale(1.1);
}

.header__contacts a.only-icon {
    margin-right: 15px;
}

.burger-wrapper {
    display: flex;
}

.burger {
    position: relative;
    width: 35px;
    height: 24px;
    background: none;
    box-shadow: none;
    border: none;
    outline: 0;
    cursor: pointer;
    z-index: 10;
}

.burger span {
    position: absolute;
    left: 50%;
    width: 35px;
    height: 5px;
    background-color: var(--main-color-2);
}
.burger span:nth-child(1) {
    top: 0;
    transform: translateX(-50%) translateY(0);
    transition: top 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87) 0.3s, transform 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87);
}
.burger span:nth-child(2) {
    top: 10px;
    opacity: 1;
    transform: translateX(-50%);
}
.burger span:last-child {
    top: 20px;
    transform: translateX(-50%) translateY(0);
    transition: top 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87) 0.3s, transform 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87);
}
.burger.open span:nth-child(1) {
    top: 50%;
    transform: translateX(-50%) translateY(0) rotate(45deg);
    transition: top 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87), transform 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87) 0.3s;
}
.burger.open span:nth-child(2) {
    opacity: 0;
}
.burger.open span:last-child {
    top: 50%;
    transform: translateX(-50%) translateY(0) rotate(-45deg);
    transition: bottom 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87), transform 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87) 0.3s;
}

.user-info, .user-info a {
    display: flex;
    align-items: center;
    gap: 30px;
}

.user-info {
    margin: 0 30px 0 0;
}

.header-logo-user {
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 100px;
    object-fit: cover;
}

/* Adaptive */

@media (max-width: 1040px) {
    .header__contacts a {
        font-size: 14px;
    }

    .header__contacts {
        margin-left: 30px;
    }
}

@media (max-width: 950px) {
    .header__contacts a {
        margin-right: 20px;
    }
    .header__contacts {
        margin-right: 40px;
    }
}

@media (max-width: 890px) {
    .header__contacts a.only-icon {
        display: none;
    }
}

@media (max-width: 850px) {
    .header__contacts {
        display: none;
    }

    .header > .container {
        justify-content: space-between;
    }

    .user-info {
        margin: 0 30px 0 auto;
    }
}

@media (max-width: 480px) {
    .header {
        height: 50px;
    }

    .logo {
        max-width: 127px;
    }

    .logo svg {
        width: 100%;
    }

    .burger {
        width: 28px;
        height: 20px;
    }

    .burger span {
        height: 4px;
        width: 28px;
    }

    .burger span:last-child {
        top: 17px;
    }

    .burger span:nth-child(2) {
        top: 8px;
    }
}