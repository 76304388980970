.statistic {
    padding: 100px 20px 110px;
}

.statistic h2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    margin-bottom: 70px;
}

.statistic-block {
    text-align: center;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    font-weight: 500;
    font-size: 100px;
    line-height: 117px;
}

.statistic-block > div {
    max-width: 312px;
}

.statistic-block svg {
    display: block;
    margin: 0 auto 35px;
}

.statistic-block p {
    font-weight: 500;
    font-size: 35px;
    line-height: 41px;
    margin-top: 30px;
}

@media (max-width: 1050px) {
    .statistic-block svg {
        width: 251px;
        height: auto;
    }

    .statistic-block {
        font-size: 75px;
        line-height: 65px;
    }

    .statistic-block p {
        font-size: 25px;
    }
}

@media (max-width: 850px) {
    .statistic-block {
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .statistic-block svg {
        width: 190px;
        height: auto;
    }
}

@media (max-width: 576px) {
    .statistic-block {
        font-size: 35px;
        line-height: 41px;
    }

    .statistic-block svg {
        width: 140px;
        height: auto;
        margin-bottom: 11px;
    }

    .statistic-block p {
        font-size: 15px;
        line-height: 18px;
        margin-top: 4px;
        max-width: 140px;
    }

    .statistic {
        padding: 65px 20px;
    }

    .statistic h2 {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 60px;
    }
}