.file {
    display: flex;
    align-items: center;
    gap: 30px;
}

.file__wrap {
    position: relative;
    min-width: 100px;
}

.file input[type="file"] {
    appearance: none;
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.file__desc {
    max-width: 286px;
    font-size: 15px;
    line-height: 18px;
}

.file__img {
    width: 100px;
    min-width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100%;
}