.main-intro {
    position: relative;
    width: 100%;
    height: calc(100vh - 70px);
    overflow: hidden;
    z-index: 1;
}

.main-intro__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 56px;
    height: 100vh;
    padding: 10px 0 120px;
    z-index: 250;
}

.main-intro__content h1 {
    font-weight: 700;
    font-size: 50px;
    line-height: 59px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
}

.main-intro__content.opened {}

.octobus-close {
    position: fixed;
    z-index: 3100;
    background: none;
    cursor: pointer;
    border: none;
    right: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
    display: none;
}

.main-intro__content.opened .octobus-close {
    display: block;
}

.octobus-close > svg {
    width: 100%;
    height: 100%;
}

.main-intro__content.opened .octobus__section.search.result__search {
    display: none !important;
}

.main-intro__content.opened #app {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 3000;
    background: #fff;
}

.main-intro__content.opened #app .result {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.main-intro__content.opened #app .result section:nth-child(4) {
    max-height: initial;
}
@media (max-width: 850px) {
    .main-intro__content h1 {
        font-size: 43px;
        padding: 135px 15px 0;
    }

    .main-intro {
        min-height: 690px;
    }

    .main-intro__content {
        height: initial;
    }
}

@media (max-width: 480px) {
    .main-intro__content h1 {
        font-size: 25px;
        line-height: 35px;
    }
}